import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bridge from '@vkontakte/vk-bridge';
import { Panel, Button } from '@vkontakte/vkui';
import GA from 'react-ga';

import ActionCard from '../../components/ActionCard';

import Icon24LogoVk from '@vkontakte/icons/dist/24/logo_vk';
import Icon24Notification from '@vkontakte/icons/dist/24/notification';
import Icon24Services from '@vkontakte/icons/dist/24/services';

const Fourth = ({ id, nextSlide }) => {
    const [group, setGroup] = useState(false);
    const [notify, setNofify] = useState(false);
    const [fav, setFav] = useState(false);

    const subscribeToGroup = () => {
        bridge.send('VKWebAppJoinGroup', {
            group_id: 193801345,
        }).then(() => setGroup(true));

        GA.event({
            category: 'User',
            action: 'subscribe_to_official_group',
        });
    };

    const subscribeToNotify = () => {
        bridge.send('VKWebAppAllowNotifications', {})
            .then(() => setNofify(true));

        GA.event({
            category: 'User',
            action: 'subscribe_to_notify',
        });
    };

    const addToFavorites = () => {
        bridge.send('VKWebAppAddToFavorites', {})
            .then(() => setFav(true));

        GA.event({
            category: 'User',
            action: 'add_to_favorites',
        });
    };

    return (
        <Panel id={id} separator={false}>
            <div className="onboard-slide">
                <div className="onboard-slide__top onboard-slide__top">
                    <div className="onboard-slide__title">Секундочку!</div>
                    <div className="onboard-slide__subtitle">Будь круче, чем другие новички прямо сейчас</div>
                </div>
                <div className="onboard-slide__content">
                    <ActionCard
                        done={group}
                        icon={<Icon24LogoVk/>}
                        title="Подпишись на группу"
                        subtitle="Чтобы знать всё об игре"
                        action={subscribeToGroup}
                    />
                    <ActionCard
                        done={notify}
                        icon={<Icon24Notification/>}
                        title="Включить уведомления"
                        subtitle="Чтобы быть в курсе всех событий"
                        action={subscribeToNotify}
                    />
                    <ActionCard
                        done={fav}
                        icon={<Icon24Services/>}
                        title="Добавь в избранные"
                        subtitle="Чтобы не потерять Nimble"
                        action={addToFavorites}
                    />
                </div>
                <div className="onboard-slide__bottom">
                    <Button
                        size="xl"
                        mode={group && notify && fav ? 'overlay_primary' : 'overlay_outline'}
                        onClick={nextSlide}
                    >
                        {group && notify && fav ? 'Поехали!' : 'Нет, спасибо'}
                    </Button>
                </div>
            </div>
        </Panel>
    );
};

Fourth.propTypes = {
    id: PropTypes.string.isRequired,
    nextSlide: PropTypes.func.isRequired,
};

export default Fourth;
