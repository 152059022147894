import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Panel, CardScroll } from '@vkontakte/vkui';
import './index.scss';

import Widget from '../../components/Widget';
import RuleCard from '../../components/RuleCard';

import PlayerIcon from '../../assets/players.png';
import QuestionsIcon from '../../assets/questions.png';
import TimeIcon from '../../assets/timer.png';
import BotIcon from '../../assets/robot.png';

const Game = ({ id, openView, openTab }) => {
    const isUserLoaded = useSelector((store) => store.user.loaded);
    const isUserActive = useSelector((store) => store.user.data.active);
    const user = useSelector((store) => store.user.data);
    return (
        <Panel id={id} centered separator={false}>
            <div className="game">
                <div className="game__top">
                    {isUserLoaded && isUserActive && (
                        <Widget
                            points={user.rating || 0}
                            onMatch={() => openTab('matches')}
                        />
                    )}
                </div>

                <div className="game__rules">
                    <CardScroll>
                        <RuleCard
                            icon={PlayerIcon}
                            title="2 игрока"
                            description="По очереди отвечают на интересные вопросы"
                        />
                        <RuleCard
                            icon={QuestionsIcon}
                            title="20 вопросов"
                            description="Быстрый темп игры и небольшое количество вопросов не дадут вам заскучать"
                        />
                        <RuleCard
                            icon={TimeIcon}
                            title="20 секунд"
                            description="Даётся на то, чтобы вы правильно ответили на каждый вопрос"
                        />
                        <RuleCard
                            icon={BotIcon}
                            title="Нимбл"
                            description="Игру проводит специально обученный бот - Нимбл"
                        />
                    </CardScroll>
                </div>

                <div className="game__bottom">
                    <div
                        className={`game__button ${(!isUserLoaded || !isUserActive) && 'game__button-inactive'} ${user.match_id && 'game__button-finded'}`}
                        onClick={isUserLoaded && isUserActive ? () => openView('mm') : null}
                    >
                        <div className="game__button__content">Играть</div>
                    </div>
                </div>
            </div>
        </Panel>
    );
};

Game.propTypes = {
    id: PropTypes.string.isRequired,
    openView: PropTypes.func.isRequired,
    openTab: PropTypes.func.isRequired,
};

export default Game;
