import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Panel, PanelHeader, CardGrid, Div, Spinner, Placeholder, Footer } from '@vkontakte/vkui';

import * as API from '../../api';
import { loadMatches } from '../../store/matches/actions';

import MatchCard from '../../components/MatchCard';

import Icon28PlayRectangleStackOutline from '@vkontakte/icons/dist/28/play_rectangle_stack_outline';

const Matches = ({ id, changeModal }) => {
    const dispatch = useDispatch();
    const isMatchesLoaded = useSelector((store) => store.matches.loaded);
    const matches = useSelector((store) => store.matches.list);

    useEffect(() => {
        API.getMatches()
            .then((matches) => {
                dispatch(loadMatches(matches));
            })
            .catch(() => null);
    }, []);

    return (
        <Panel id={id} centered={isMatchesLoaded && matches.length === 0}>
            <PanelHeader>
                Матчи
            </PanelHeader>
            {isMatchesLoaded ?
                matches.length ? (
                        <Div>
                            <CardGrid>
                                {matches.map((match) => (
                                    <MatchCard
                                        key={match._id}
                                        match={match}
                                        changeModal={changeModal}
                                    />
                                ))}
                            </CardGrid>
                            <Footer>
                                Показывается история последних 10 матчей
                            </Footer>
                        </Div>
                    )
                : (
                    <Placeholder
                        icon={<Icon28PlayRectangleStackOutline width={56} height={56} fill="#4BB34B" />}
                        header="История ваших матчей пуста"
                    >
                        Как только вы сыграете свой первый матч, он сразу же появится здесь
                    </Placeholder>
                )
            : (
                <div style={{ marginTop: 15 }}>
                    <Spinner/>
                </div>
            )}
        </Panel>
    );
};

Matches.propTypes = {
    id: PropTypes.string.isRequired,
    changeModal: PropTypes.func.isRequired,
};

export default Matches;
