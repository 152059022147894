import React, { useState, useEffect } from 'react';
import GA from 'react-ga';
import { useSelector } from 'react-redux';
import { Root, Tabbar, TabbarItem, Epic, View, ModalRoot } from '@vkontakte/vkui';
import * as API from '../api';
import * as Sentry from '@sentry/browser';

import Onboard from './Onboard';
import Profile from './Profile';
import ProfileOther from './ProfileOther';
import Game from './Game';
import Rating from './Rating';
import EventRating from './EventRating';
import Matches from './Macthes';
import Market from './Market';
import MatchMaking from './MatchMaking';
import SelectGroup from './SelectGroup';

import Admin from './Admin';
import SystemStats from './SystemStats';

import SelectGroupModal from './SelectGroupModal';
import DeliveryCodeModal from './DeliveryCodeModal';

import Icon28CalendarOutline from '@vkontakte/icons/dist/28/calendar_outline';
import Icon28GraphOutline from '@vkontakte/icons/dist/28/graph_outline';
import Icon28BrainOutline from '@vkontakte/icons/dist/28/brain_outline';
import Icon28PlayRectangleStackOutline from '@vkontakte/icons/dist/28/play_rectangle_stack_outline';
import Icon28UserCircleOutline from '@vkontakte/icons/dist/28/user_circle_outline';

const App = () => {
    const user = useSelector((store) => store.user.data);
    const [started, setStarted] = useState(false);
    const [activeView, setActiveView] = useState('main');
    const [activeStory, setActiveStory] = useState('game');
    const [activeModal, setActiveModal] = useState(null);
    const [activeEvent, setActiveEvent] = useState({});
    const onStoryChange = (e) => setActiveStory(e.currentTarget.dataset.story);
    const tabbar = (
        <Tabbar>
            <TabbarItem
                onClick={onStoryChange}
                selected={activeStory === 'market'}
                data-story="market"
                text="События"
            >
                <Icon28CalendarOutline />
            </TabbarItem>
            <TabbarItem
                onClick={onStoryChange}
                selected={activeStory === 'rating'}
                data-story="rating"
                text="Рейтинг"
            >
                <Icon28GraphOutline />
            </TabbarItem>
            <TabbarItem
                onClick={onStoryChange}
                selected={activeStory === 'game'}
                data-story="game"
                text="Игра"
            >
                <Icon28BrainOutline />
            </TabbarItem>
            <TabbarItem
                onClick={onStoryChange}
                selected={activeStory === 'matches'}
                data-story="matches"
                text="Матчи"
            >
                <Icon28PlayRectangleStackOutline />
            </TabbarItem>
            <TabbarItem
                onClick={onStoryChange}
                selected={activeStory === 'profile'}
                data-story="profile"
                text="Профиль"
            >
                <Icon28UserCircleOutline />
            </TabbarItem>
        </Tabbar>
    );

    useEffect(() => {
        if (Object.keys(user).length !== 0 && !started) {
            setStarted(true);
            API.getVKStore()
                .then((storage) => {
                    if (storage.welcome !== '1') {
                        return setActiveView('onboard');
                    } else {
                        if (user.invited_group !== null) {
                            setActiveModal('select-group-modal');
                            GA.event({
                                category: 'User',
                                action: 'Open from group',
                                label: String(user.invited_group.id),
                            });
                        }
                    }
                });
        }
    }, [started, user]);

    useEffect(() => {
        if (activeView === 'main') {
            GA.pageview(`/main/${activeStory}`);
            Sentry.setTag('route', `/main/${activeStory}`);
        } else {
            GA.pageview(`/${activeView}`);
            Sentry.setTag('route', `/${activeView}`);
        }
    }, [activeView, activeStory]);

    useEffect(() => {
        if (window.vkapp_config.vk_ref) {
            GA.event({
                category: 'Traffic',
                action: String(window.vkapp_config.vk_ref),
            });
        }
    }, []);

    const modals = (
        <ModalRoot activeModal={activeModal}>
            <SelectGroupModal id="select-group-modal" changeModal={setActiveModal} />
            <DeliveryCodeModal id="delivery-code-modal" changeModal={setActiveModal} />
        </ModalRoot>
    );

    return (
        <Root
            activeView={activeView}
            modal={modals}
            onTransition={({ from, to }) => {
                if (from === 'onboard' && to === 'main' && user.invited_group !== null) {
                    setActiveModal('select-group-modal');
                    GA.event({
                        category: 'User',
                        action: 'Start from group',
                        label: String(user.invited_group.id),
                    });
                }
            }}
        >
            <Epic id="main" activeStory={activeStory} tabbar={tabbar}>
                <View id="market" activePanel="market" header={false}>
                    <Market id="market" openTab={setActiveStory} openEvent={setActiveEvent} />
                </View>
                <View id="rating" activePanel="rating">
                    <Rating id="rating" openTab={setActiveStory} />
                </View>
                <View id="game" activePanel="game" header={false}>
                    <Game id="game" openView={setActiveView} openTab={setActiveStory} />
                </View>
                <View id="matches" activePanel="matches">
                    <Matches id="matches" changeModal={setActiveModal} />
                </View>
                <View id="profile" activePanel="profile">
                    <Profile id="profile" openView={setActiveView} openTab={setActiveStory} />
                </View>
                <View id="admin" activePanel="admin">
                    <Admin id="admin" openTab={setActiveStory} />
                </View>
                <View id="profile-other" activePanel="profile-other">
                    <ProfileOther id="profile-other" openTab={setActiveStory} />
                </View>
                <View id="system-stats" activePanel="system-stats">
                    <SystemStats id="system-stats" openTab={setActiveStory} />
                </View>
                <View id="event-rating" activePanel="event-rating">
                    <EventRating id="event-rating" openTab={setActiveStory} event={activeEvent} />
                </View>
            </Epic>
            <View id="mm" activePanel="mm" header={false}>
                <MatchMaking id="mm" openView={setActiveView} />
            </View>
            <View id="select-group" activePanel="select-group">
                <SelectGroup id="select-group" openView={setActiveView} />
            </View>
            <Onboard id="onboard" openView={setActiveView} />
        </Root>
    );
};

export default App;
