import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import bridge from '@vkontakte/vk-bridge';
import GA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import * as API from '../../api';
import fireEvent from '../../utils/FireEvent';
import { Panel, PanelHeader, Tabs, TabsItem, List, Cell, Avatar, Div, Spinner } from '@vkontakte/vkui';
import './index.scss';

import { openOtherProfile } from '../../store/user/actions';
import { loadRating } from '../../store/rating/actions';

const Rating = ({ id, openTab }) => {
    const dispatch = useDispatch();
    const [type, setType] = useState('users');

    const me = useSelector((store) => store.user.data);

    const isUsersLoaded = useSelector((store) => store.rating.loaded_users);
    const users = useSelector((store) => store.rating.users);

    const isGroupsLoaded = useSelector((store) => store.rating.loaded_groups);
    const groups = useSelector((store) => store.rating.groups);

    const [isFriendsLoaded, setIsFriendsLoaded] = useState(false);
    const [friends, setFriends] = useState([]);

    const loadFriends = (accessToken, offset = 0) => bridge.send('VKWebAppCallAPIMethod', {
        method: 'friends.get',
        params: {
            v: '5.103',
            access_token: accessToken,
            offset,
        },
    })
        .then((response) => response.response)
        .catch(() => ({}));

    useEffect(() => {
        if (type === 'users') {
            if (!isUsersLoaded) {
                API.getUsersRating()
                    .then((users) => {
                        if (users !== null) {
                            dispatch(loadRating('users', users));
                        } else {
                            dispatch(loadRating('users', []));
                        }
                    });
            }
        }

        if (type === 'groups') {
            if (!isGroupsLoaded) {
                API.getGroupsRating()
                    .then((groups) => {
                        if (groups !== null) {
                            dispatch(loadRating('groups', groups));
                        } else {
                            dispatch(loadRating('groups', []));
                        }
                    });
            }
        }

        if (type === 'friends') {
            bridge.send('VKWebAppGetAuthToken', {
                app_id: window.vkapp_config.vk_app_id,
                scope: 'friends',
            })
                .then(async ({ access_token }) => {
                    const response = await loadFriends(access_token);

                    let friends = response.items;
                    let all = response.count;
                    while (friends.length < all) {
                        const response = await loadFriends(response.access_token, friends.count);
                        friends = [...friends, ...response.items];
                    }

                    API.getFriendsTop(friends)
                        .then((friends) => {
                            setFriends([...friends, me].sort((a, b) => b.rating - a.rating));
                            setIsFriendsLoaded(true);
                        })
                        .catch(() => null);
                })
                .catch(() => null);
        }
    }, [type]);

    useEffect(() => {
        GA.event({
            category: 'Rating',
            action: type,
        });
    }, [type]);

    return (
        <Panel id={id} separator={false}>
            <PanelHeader
                separator={false}
            >
                Рейтинг
            </PanelHeader>
            <Tabs>
                <TabsItem
                    onClick={() => setType('users')}
                    selected={type === 'users'}
                >
                    Игроки
                </TabsItem>
                <TabsItem
                    onClick={() => setType('friends')}
                    selected={type === 'friends'}
                >
                    Друзья
                </TabsItem>
                <TabsItem
                    onClick={() => setType('groups')}
                    selected={type === 'groups'}
                >
                    Сообщества
                </TabsItem>
            </Tabs>
            {{
                'users': (
                    isUsersLoaded ?
                        <Div>
                            <List>
                                {users.map((user) => (
                                    <Cell
                                        key={user._id}
                                        asideContent={user.rating}
                                        onClick={() => {
                                            if (!!me.staff) {
                                                dispatch(openOtherProfile({ _id: user._id, from: 'rating' }));
                                                openTab('profile-other');
                                            } else {
                                                fireEvent('https://vk.com/id' + user._id);
                                            }
                                        }}
                                        before={
                                            <Avatar
                                                src={user.photo_200}
                                            />
                                        }
                                    >
                                        {user.first_name} {user.last_name}
                                    </Cell>
                                ))}
                            </List>
                        </Div>
                    :
                        <div style={{ marginTop: 15 }}>
                            <Spinner/>
                        </div>
                ),
                'friends': (
                    isFriendsLoaded ?
                        <Div>
                            <List>
                                {friends.map((user) => (
                                    <Cell
                                        key={user._id}
                                        asideContent={user.rating}
                                        onClick={() => {
                                            if (!!me.staff) {
                                                dispatch(openOtherProfile({ _id: user._id, from: 'rating' }));
                                                openTab('profile-other');
                                            } else {
                                                fireEvent('https://vk.com/id' + user._id);
                                            }
                                        }}
                                        before={
                                            <Avatar
                                                src={user.photo_200}
                                            />
                                        }
                                    >
                                        {user.first_name} {user.last_name}
                                    </Cell>
                                ))}
                            </List>
                        </Div>
                        :
                        <div style={{ marginTop: 15 }}>
                            <Spinner/>
                        </div>
                ),
                'groups': (
                    isGroupsLoaded ?
                        <Div>
                            <List>
                                {groups.map((group) => (
                                    <Cell
                                        key={group._id}
                                        asideContent={group.rating}
                                        onClick={() => fireEvent('https://vk.com/club' + group._id)}
                                        before={
                                            <Avatar
                                                src={group.photo_200}
                                            />
                                        }
                                    >
                                        {group.name}
                                    </Cell>
                                ))}
                            </List>
                        </Div>
                    :
                        <div style={{ marginTop: 15 }}>
                            <Spinner/>
                        </div>
                ),
            }[type]}
        </Panel>
    );
};

Rating.propTypes = {
    id: PropTypes.string.isRequired,
    openTab: PropTypes.func.isRequired,
};

export default Rating;
