import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Panel, Button } from '@vkontakte/vkui';
import './index.scss';

import Message from '../../components/Message';

const Second = ({ id, nextSlide }) => {
    const [messages, setMessages] = useState([
        {
            avatar: 'https://sun9-12.userapi.com/c857032/v857032375/147ed7/yFXOgFQQHpw.jpg?ava=1',
            from: 'Nimble',
            text: 'Степан, Когда основали социальную сеть ВКонтакте?',
            time: '04:20',
            buttons: [
                {
                    text: '2006',
                },
                {
                    text: '2007',
                },
                {
                    text: '2008',
                },
            ],
        },
    ]);

    useEffect(() => {
        setTimeout(() => setMessages((prev) => [
            ...prev,
            {
                from: 'me',
                text: '2006',
                time: '04:20',
            },
        ]), 750);
        setTimeout(() => setMessages((prev) => [
            ...prev,
            {
                avatar: 'https://sun9-12.userapi.com/c857032/v857032375/147ed7/yFXOgFQQHpw.jpg?ava=1',
                from: 'Nimble',
                text: 'Отлично! Это правильный ответ!',
                time: '04:20',
            },
        ]), 1250);
    }, []);

    return (
        <Panel id={id} separator={false}>
            <div className="onboard-slide onboard-slide-normal">
                <div className="onboard-slide__top onboard-slide__top-normal">
                    <div className="onboard-slide__title">Отвечай на вопросы</div>
                    <div className="onboard-slide__subtitle">Побеждает тот, кто ответил правильно больше всего раз</div>
                    <Button
                        size="l"
                        mode="overlay_primary"
                        onClick={nextSlide}
                    >
                        Понятно
                    </Button>
                </div>
                <div className="onboard-slide__content onboard-slide__content-message">
                    <div className="onboard-slide__dialog">
                        {messages.map((msg) => (
                            <Message key={msg.text} {...msg} />
                        ))}
                    </div>
                </div>
            </div>
        </Panel>
    );
};

Second.propTypes = {
    id: PropTypes.string.isRequired,
    nextSlide: PropTypes.func.isRequired,
};

export default Second;
