import React from 'react';
import GA from 'react-ga';
import PropTypes from 'prop-types';
import * as API from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { ModalCard, Avatar } from '@vkontakte/vkui';

import { userUpdateGroup } from '../../store/user/actions';

const SelectGroupModal = ({ id, changeModal }) => {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.user.data);

    const selectGroup = (group) => {
        API.changeGroup(group.id)
            .then(() => {
                dispatch(userUpdateGroup(group));

                GA.event({
                    category: 'User',
                    action: 'select_group',
                });
            });
    };

    return (
        <ModalCard
            id={id}
            onClose={() => changeModal(null)}
            icon={<Avatar src={user.invited_group && user.invited_group.photo_200} size={56} />}
            header={`Присоединиться к сообществу ${(user.invited_group && user.invited_group.name) || ''}?`}
            caption="Вы зашли в игру из группы этого сообщества и если желаете помочь попасть ему в топ, то Вам необходимо установить его как свое основное."
            actions={[{
                title: 'Присоединиться',
                mode: 'primary',
                action: () => {
                    selectGroup(user.invited_group || {});
                    changeModal(null);
                    GA.event({
                        category: 'User',
                        action: 'Confirm Invite',
                        label: String(user.invited_group && user.invited_group.id) || '',
                    });
                },
            }]}
        >

        </ModalCard>
    );
};

SelectGroupModal.propTypes = {
    id: PropTypes.string.isRequired,
    changeModal: PropTypes.func.isRequired,
};

export default SelectGroupModal;
