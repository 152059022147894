import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Panel, PanelHeader, Group, Header, Spinner } from '@vkontakte/vkui';
import * as API from '../../api';

import EventCard from '../../components/EventCard';

const Market = ({ id, openTab, openEvent }) => {
    const [loaded, setLoaded] = useState(false);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        API.getEvents()
            .then((events) => {
                setEvents(events);
                setLoaded(true);
            });
    }, []);

    return (
        <Panel id={id} separator={false}>
            <PanelHeader>События</PanelHeader>
            {loaded ? (
                <>
                    {events.filter((x) => !x.partner && x.status === 'live').length > 0 && (
                        <Group>
                            <Header mode="secondary">Официальные события</Header>
                            {events.filter((x) => !x.partner && x.status === 'live').map((x) => (
                                <EventCard key={x._id} event={x} openTab={openTab} openEvent={openEvent} />
                            ))}
                        </Group>
                    )}
                    {events.filter((x) => !!x.partner && x.status === 'live').length > 0 && (
                        <Group>
                            <Header mode="secondary">От партнёров</Header>
                            {events.filter((x) => !!x.partner && x.status === 'live').map((x) => (
                                <EventCard key={x._id} event={x} openTab={openTab} openEvent={openEvent} />
                            ))}
                        </Group>
                    )}
                    <Group>
                        <Header mode="secondary">Завершённые события</Header>
                        {[...events.filter((x) => x.status === 'finished')].reverse().map((x) => (
                            <EventCard key={x._id} event={x} openTab={openTab} openEvent={openEvent} />
                        ))}
                    </Group>
                </>
            ) : (
                <div
                    style={{
                        marginTop: 15,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Spinner />
                </div>
            )}
        </Panel>
    );
};

Market.propTypes = {
    id: PropTypes.string.isRequired,
    openTab: PropTypes.func.isRequired,
    openEvent: PropTypes.func.isRequired,
};

export default Market;
