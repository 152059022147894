import * as types from './actionTypes';

export const loadMatches = (matches = []) => {
    return {
        type: types.MATCHES_LOAD,
        matches,
    };
};

export const addMatch = (match = {}) => {
    return {
        type: types.MATCHES_ADD,
        match,
    };
};
