import React from 'react';
import './index.scss';

const Dodecahedron = () => {
    return (
        <div className="dodecahedron">
            <div className="dodecahedron__base">
                <div className="dodecahedron__side"/>
                <div className="dodecahedron__side"/>
                <div className="dodecahedron__side"/>
                <div className="dodecahedron__side"/>
                <div className="dodecahedron__side"/>
                <div className="dodecahedron__side"/>
            </div>
        </div>
    );
};

export default Dodecahedron;
