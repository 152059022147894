import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import GA from 'react-ga';
import * as API from '../../api';
import bridge from '@vkontakte/vk-bridge';
import { Panel, PanelHeader, PanelHeaderBack, Spinner, List, Cell, Group, Avatar, Placeholder, Button } from '@vkontakte/vkui';

import { userUpdateGroup } from '../../store/user/actions';

import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';
import Icon56DoNotDisturbOutline from '@vkontakte/icons/dist/56/do_not_disturb_outline';

const SelectGroup = ({ id, openView }) => {
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    const [rejected, setRejected] = useState(false);
    const [groups, setGroups] = useState([]);

    const load = () => {
        bridge.send('VKWebAppGetAuthToken', {
            app_id: window.vkapp_config.vk_app_id,
            scope: 'groups',
        })
            .then((response) => {
                bridge.send('VKWebAppCallAPIMethod', {
                    method: 'groups.get',
                    params: {
                        extended: '1',
                        v: '5.103',
                        access_token: response.access_token,
                    },
                })
                    .then((response) => {
                        setLoaded(true);
                        setRejected(false);
                        setGroups([
                            {
                                id: '-1',
                                photo_200: 'null',
                                name: 'Убрать группу',
                            },
                            ...response.response.items,
                        ]);
                    })
                    .catch(() => setRejected(true));
            })
            .catch(() => setRejected(true));
    };

    const selectGroup = (group) => {
        API.changeGroup(group.id)
            .then(() => {
                dispatch(userUpdateGroup(group));
                openView('main');

                GA.event({
                    category: 'User',
                    action: 'select_group',
                });
            });
    };

    useEffect(() => {
        load();
    }, []);

    return (
        <Panel id={id}>
            <PanelHeader
                left={<PanelHeaderBack onClick={() => openView('main')} />}
            >
                Выберите группу
            </PanelHeader>
            {
                rejected ? (
                    <Placeholder
                        icon={<Icon56DoNotDisturbOutline fill="#4BB34B" />}
                        header="Что-то пошло не так"
                        action={
                            <Button
                                size="l"
                                onClick={load}
                            >
                                Повторить попытку
                            </Button>
                        }
                    >
                        Нам нужен доступ к списку ваших групп
                    </Placeholder>
                ) : !loaded ? (
                    <div>
                        <div style={{ marginTop: 15 }}>
                            <Spinner/>
                        </div>
                    </div>
                ) : (
                    <Group>
                        <List>
                            {groups.map((group) => (
                                <Cell
                                    key={group.id}
                                    onClick={() => selectGroup(group)}
                                    before={
                                        group.photo_200 === 'null' ? (
                                            <Avatar>
                                                <Icon24Cancel />
                                            </Avatar>
                                        ) : (
                                            <Avatar src={group.photo_200} />
                                        )
                                    }
                                >
                                    {group.name}
                                </Cell>
                            ))}
                        </List>
                    </Group>
                )
            }
        </Panel>
    );
};

SelectGroup.propTypes = {
    id: PropTypes.string.isRequired,
    openView: PropTypes.func.isRequired,
};

export default SelectGroup;
