import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './index.scss';

import Icon24Chevron from '@vkontakte/icons/dist/24/chevron';
import Icon24Favorite from '@vkontakte/icons/dist/24/favorite';

const Widget = ({ points, onMatch }) => {
    const isMatch = useSelector((store) => store.user.data.match_id);
    return (
        <div
            className={`widget ${isMatch && 'widget-match'}`}
            onClick={isMatch ? onMatch : null}
        >
            {isMatch ? (
                <>
                    <div className="widget__text">Текущий матч</div>
                    <Icon24Chevron fill="white" />
                </>
            ) : (
                <>
                    <div className="widget__text" style={{ marginRight: 5 }}>{points}</div>
                    <Icon24Favorite width="20" fill="white" style={{ marginRight: -2, marginBottom: 1 }} />
                </>
            )}
        </div>
    );
};

Widget.propTypes = {
    points: PropTypes.number.isRequired,
    onMatch: PropTypes.func.isRequired,
};

export default Widget;
