import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import * as API from '../../api';
import fireEvent from '../../utils/FireEvent';
import { Panel, PanelHeader, PanelHeaderBack, List, Cell, Avatar, Div, Spinner, FixedLayout, Separator } from '@vkontakte/vkui';
import '../Rating/index.scss';
import { openOtherProfile } from '../../store/user/actions';

const EventRating = ({ id, openTab, event }) => {
    const dispatch = useDispatch();
    const isStaff = useSelector((store) => store.user.data.staff);

    const [me, setMe] = useState(null);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (event.type === 'daily-wins') {
            API.getDailyWinsRating(event._id).then(setUsers);
        } else if (event.type === 'daily-answers') {
            API.getDailyAnswersRating(event._id).then(setUsers);
        } else if (event.type === 'answers') {
            API.getAnswersRating(event._id).then(setUsers);
        } else if (event.type === 'rating') {
            API.getEventRating(event._id)
                .then((response) => {
                    setUsers(response.rating);
                    setMe(response.me);
                });
        }
    }, []);

    useEffect(() => {
        GA.event({
            category: 'Events',
            action: 'Rating',
            label: 'Daily Wins',
        });
    }, []);

    return (
        <Panel id={id} separator={false}>
            <PanelHeader
                left={
                    <PanelHeaderBack onClick={() => openTab('market')} />
                }
            >
                Рейтинг
            </PanelHeader>
            {users.length > 0 ?
                <Div>
                    <List>
                        {users.map((user) => (
                            <Cell
                                key={user._id}
                                asideContent={user.dailyWins || user.dailyAnswers || user.answers || user.rating}
                                onClick={() => {
                                    if (!!isStaff) {
                                        dispatch(openOtherProfile({ _id: user._id, from: 'event-rating' }));
                                        openTab('profile-other');
                                    } else {
                                        fireEvent('https://vk.com/id' + user._id);
                                    }
                                }}
                                before={
                                    <Avatar
                                        src={user.profile.photo_200}
                                    />
                                }
                            >
                                {user.profile.first_name} {user.profile.last_name}
                            </Cell>
                        ))}
                    </List>
                </Div>
                :
                <div style={{ marginTop: 15 }}>
                    <Spinner/>
                </div>
            }
            {users.length > 0 && me !== null && (
                <>
                    <div style={{ height: '82px' }} />
                    <FixedLayout vertical="bottom" style={{ background: 'var(--background_content)' }}>
                        <Separator wide />
                        <Div>
                            <Cell
                                asideContent={me.dailyWins || me.dailyAnswers || me.answers || me.rating}
                                before={
                                    <Avatar
                                        src={me.profile.photo_200}
                                    />
                                }
                            >
                                {me.profile.first_name} {me.profile.last_name}
                            </Cell>
                        </Div>
                    </FixedLayout>
                </>
            )}
        </Panel>
    );
};

EventRating.propTypes = {
    id: PropTypes.string.isRequired,
    openTab: PropTypes.func.isRequired,
    event: PropTypes.any.isRequired,
};

export default EventRating;
