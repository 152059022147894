import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Button } from '@vkontakte/vkui';
import './index.scss';

import Dodecahedron from '../../components/Dodecahedron';

const First = ({ id, nextSlide }) => {
    return (
        <Panel id={id} separator={false}>
            <div className="onboard-slide">
                <div className="onboard-slide__top">
                    <div className="onboard-slide__title onboard-slide__title-big">Nimble</div>
                    <div className="onboard-slide__subtitle">Реактивные вопросы 🔥</div>
                </div>
                <Dodecahedron />
                <div className="onboard-slide__bottom">
                    <Button
                        size="xl"
                        mode="overlay_primary"
                        onClick={nextSlide}
                    >
                        Начать
                    </Button>
                </div>
            </div>
        </Panel>
    );
};

First.propTypes = {
    id: PropTypes.string.isRequired,
    nextSlide: PropTypes.func.isRequired,
};

export default First;
