import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Panel, PanelHeader, PanelHeaderBack, Div, Avatar, Group, Header, List, Cell, CellButton } from '@vkontakte/vkui';
import * as API from '../../api';

import Graph from '../../components/Graph';
import GraphBar from '../../components/GraphBar';
import wordPad from '../../utils/wordPad';
import fireEvent from '../../utils/FireEvent';

import Icon28Favorite from '@vkontakte/icons/dist/28/favorite';
import Icon28BrainOutline from '@vkontakte/icons/dist/28/brain_outline';
import Icon28Profile from '@vkontakte/icons/dist/28/profile';
import Icon28RemoveCircleOutline from '@vkontakte/icons/dist/28/remove_circle_outline';
import Icon28Notifications from '@vkontakte/icons/dist/28/notifications';
import Icon28PrivacyOutline from '@vkontakte/icons/dist/28/privacy_outline';
import Icon28AddCircleOutline from '@vkontakte/icons/dist/28/add_circle_outline';

const ProfileOther = ({ id, openTab }) => {
    const userId = useSelector((store) => store.user.other._id);
    const fromPanel = useSelector((store) => store.user.other.from);
    const [user, setUser] = useState({});
    const [group, setGroup] = useState({});
    const [graphRating, setGraphRating] = useState([]);
    const [graphAnswers, setGraphAnswers] = useState([]);
    const [stats, setStats] = useState({});
    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        API.getUserById(userId)
            .then((user) => {
                if (user !== null) {
                    setUser({ ...user.profile, rating: user.rating });
                    setHidden(user.hide_from_rating);
                    setStats(user.statistics);
                    setGroup(user.group);
                }
            });

        API.getGraph(userId, 'rating')
            .then((res) => setGraphRating(res.items.reverse()));

        API.getGraph(userId, 'score')
            .then((res) => setGraphAnswers(res.items.reverse()));
    }, []);

    return (
        <Panel id={id}>
            <PanelHeader
                left={
                    <PanelHeaderBack onClick={() => openTab(fromPanel)} />
                }
            >
                Профиль
            </PanelHeader>
            <Group className="profile">
                <Avatar size={128} src={user && user.photo_200} />
                <div className="profile__name">{user && user.first_name} {user && user.last_name}</div>
            </Group>
            <Group>
                <List>
                    <Cell
                        multiline
                        before={
                            <Avatar style={{ background: 'var(--background_content)' }}>
                                <Icon28Favorite fill="#4BB34B" />
                            </Avatar>
                        }
                        description="Очки влияют на вашу позицию в рейтинге пользователей"
                    >
                        {user && user.rating} {wordPad(user && user.rating, 'очко', 'очка', 'очков')}
                    </Cell>
                </List>
            </Group>
            <Group>
                <Header mode="secondary">Группа</Header>
                <List>
                    <Cell
                        before={<Avatar src={group && group.photo_200} />}
                        onClick={() => fireEvent('https://vk.com/club' + group.id)}
                    >
                        {(group && group.name) || 'Не выбрана'}
                    </Cell>
                </List>
            </Group>
            <Group>
                <CellButton
                    before={<Icon28Profile />}
                    onClick={() => fireEvent('https://vk.com/id' + userId)}
                >
                    Открыть профиль
                </CellButton>
                <CellButton
                    before={<Icon28BrainOutline />}
                    onClick={() => {
                        API.createPVP(window.vkapp_config.vk_user_id, userId)
                            .then((result) => {
                                if (result !== null) {
                                    openTab('game');
                                }
                            });
                    }}
                >
                    Создать матч
                </CellButton>
                <CellButton before={<Icon28Notifications />}>Отправить уведомление</CellButton>
                <CellButton
                    before={hidden ? <Icon28AddCircleOutline/> : <Icon28RemoveCircleOutline />}
                    onClick={() => {
                        API.changeUserRatingVisible(userId).then(() => setHidden((p) => !p));
                    }}
                >
                    {hidden ? 'Отобразить в рейтинге' : 'Скрыть из рейтинга'}
                </CellButton>
                <CellButton before={<Icon28PrivacyOutline />} mode="danger">Заблокировать</CellButton>
            </Group>
            <Group>
                <Header mode="secondary">График рейтинга</Header>
                <Div>
                    <Graph source={graphRating} />
                </Div>
            </Group>
            <Group>
                <Header mode="secondary">График правильных ответов</Header>
                <Div>
                    <Graph source={graphAnswers} />
                </Div>
            </Group>
            <Group>
                <Header mode="secondary">Количество матчей по ответам</Header>
                <Div>
                    <GraphBar source={graphAnswers} />
                </Div>
            </Group>
            <Group header={<Header mode="secondary">Статистика</Header>}>
                <List>
                    <Cell
                        indicator={stats && stats.right_answers}
                    >
                        Правильных ответов
                    </Cell>
                    <Cell
                        indicator={stats && stats.all_answers}
                    >
                        Всего ответов
                    </Cell>
                    <Cell
                        indicator={stats && stats.win_matches}
                    >
                        Выигранных матчей
                    </Cell>
                    <Cell
                        indicator={stats && stats.all_matches}
                    >
                        Всего матчей
                    </Cell>
                </List>
            </Group>
        </Panel>
    );
};

ProfileOther.propTypes = {
    id: PropTypes.string.isRequired,
    openTab: PropTypes.func.isRequired,
};

export default ProfileOther;
