import * as types from './actionTypes';

const initialState = {
    loaded: false,
    list: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.MATCHES_LOAD: {
            const { matches } = action;
            return {
                ...state,
                loaded: true,
                list: [...new Set(matches)],
            };
        }

        case types.MATCHES_ADD: {
            const { match } = action;
            return {
                ...state,
                list: [...new Set([match, ...state.list])],
            };
        }
        default: return state;
    }
};
