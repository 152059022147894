import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@vkontakte/vkui';
import './index.scss';

const RuleCard = ({ icon = null, title = 'Правило #1', description = '123' }) => {
    return (
        <Card mode="shadow" className="rule-card">
            <div className="rule-card__container">
                <img className="rule-card__icon" src={icon} alt="" />
                <div className="rule-card__title">{title}</div>
                <div className="rule-card__description">{description}</div>
            </div>
        </Card>
    );
};

RuleCard.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
};

export default RuleCard;
