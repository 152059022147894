import { combineReducers } from 'redux';

import user from './user';
import rating from './rating';
import matches from './matches';

export default combineReducers({
    user,
    rating,
    matches,
});
