import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import fireEvent from '../../utils/FireEvent';
import { Card, Separator, Avatar, List, Cell, Div, Button } from '@vkontakte/vkui';
import './index.scss';

import * as API from '../../api';
import { loadUser } from '../../store/user/actions';

import Icon24LinkCircle from '@vkontakte/icons/dist/24/link_circle';

const statusResolver = (player1, player2, winner) => {
    if (player1._id === winner) {
        return `${player1.first_name} ${player1.last_name}`;
    } else if (player2._id === winner) {
        return `${player2.first_name} ${player2.last_name}`;
    } else {
        return 'Ничья';
    }
};

const MatchCard = ({ match, changeModal }) => {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.user.data);

    return (
        <Card size="l" mode="shadow">
            <div className="match-card__container">
                <div className="match-card__header">
                    <div
                        className="match-card__player"
                        onClick={() => fireEvent('https://vk.com/id' + match.player_1._id)}
                    >
                        <Avatar
                            size={72}
                            className="match-card__player__image"
                            src={match.player_1.photo_200}
                        >
                            <div className="match-card__indicator match-card__indicator-score">
                                {match.player_1.rating}
                            </div>
                        </Avatar>
                        <div className="match-card__player__name">{match.player_1.first_name}</div>
                        <div className="match-card__player__name">{match.player_1.last_name}</div>
                    </div>
                    <div
                        className="match-card__player"
                        onClick={() => fireEvent('https://vk.com/id' + match.player_2._id)}
                    >
                        <Avatar
                            size={72}
                            className="match-card__player__image"
                            src={match.player_2.photo_200}
                        >
                            <div className="match-card__indicator match-card__indicator-score">
                                {match.player_2.rating}
                            </div>
                        </Avatar>
                        <div className="match-card__player__name">{match.player_2.first_name}</div>
                        <div className="match-card__player__name">{match.player_2.last_name}</div>
                    </div>
                </div>
                <Separator wide />
                <List>
                    <Cell
                        indicator={
                            <div className={`match-card__indicator ${match.status !== 'finished' && 'match-card__indicator-live'}`}>
                                {match.status !== 'finished' ? 'LIVE' : 'Окончен'}
                            </div>
                        }
                    >
                        Статус матча
                    </Cell>
                    <Cell
                        indicator={`${match.player_1.score} : ${match.player_2.score}`}
                    >
                        Счёт
                    </Cell>
                    <Cell
                        indicator={match.status !== 'finished' ? 'Неизвестен' : statusResolver(match.player_1, match.player_2, match.winner)}
                    >
                        Победитель
                    </Cell>
                </List>
                {match.status !== 'finished' && (
                    <>
                        <Separator wide />
                        <Div>
                            <Button
                                size="xl"
                                className="match-card__button"
                                before={<Icon24LinkCircle />}
                                onClick={() => fireEvent(match.chat_url)}
                            >
                                Открыть
                            </Button>
                        </Div>
                    </>
                )}
                {match.status === 'finished' && match.type === 'delivery' && (
                    <>
                        <Separator wide />
                        <Div>
                            <Button
                                size="xl"
                                className="match-card__button match-card__button-delivery"
                                before={<img src="/assets/eats_logo.png" width={38} height={38} style={{ paddingRight: 10, marginTop: 5, marginBottom: 5, marginRight: 10, borderRight: '1px solid rgba(255, 255, 255, .5)' }} />}
                                onClick={() => {
                                    changeModal('delivery-code-modal');
                                    API.getPromocode(match._id)
                                        .then((promocode) => {
                                            dispatch(loadUser({
                                                ...user,
                                                dc_promocode: promocode,
                                            }));
                                        });
                                }}
                            >
                                Промокод Еда ВКонтакте
                            </Button>
                        </Div>
                    </>
                )}
            </div>
        </Card>
    );
};

MatchCard.propTypes = {
    match: PropTypes.object.isRequired,
};

export default MatchCard;
