import * as Sentry from '@sentry/browser';

const request = (url, params) => new Promise((resolve, reject) => {
    const updatedParams = { ...params };
    const preparedParams = Object.keys(updatedParams)
        .reduce((a, x) => [...a, `${x}=${updatedParams[x]}`], [])
        .join('&');

    const transactionId = Math.random().toString(36).substr(2, 9);
    Sentry.configureScope((scope) => {
        scope.setTag('transaction_id', transactionId);
    });

    fetch(url + '?' + preparedParams, {
        headers: {
            'X-Transaction-ID': transactionId,
        },
    })
        .then((response) => response.json())
        .then((response) => {
            if (!response.response) {
                console.log({
                    type: 'error',
                    url,
                    params,
                    response: JSON.stringify(response),
                });
                return reject(response.error);
            }

            return resolve(response.response);
        })
        .catch((e) => reject(e));
});

export default request;
