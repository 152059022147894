import React from 'react';
import PropTypes from 'prop-types';
import GA from 'react-ga';
import Countdown from 'react-countdown';
import { Banner, Button } from '@vkontakte/vkui';

import { default as fireEvent } from '../../utils/FireEvent';

const EventCard = ({ event, openEvent, openTab }) => {
    return (
        <Banner
            mode="image"
            size="m"
            header={event.title}
            subheader={
                event.type !== 'delivery' ? (
                    <span>
                        {event.description.split('\\n').map((x) => (
                            <React.Fragment key={x}>{x}<br/></React.Fragment>
                        ))}
                        <div style={{ marginTop: 10 }}>
                             <span style={{ marginRight: 5 }}>⏰</span>
                            <span>
                                <Countdown
                                    date={(new Date(event.endDate)).getTime()}
                                >
                                    <span>Событие завершилось</span>
                                </Countdown>
                            </span>
                        </div>
                    </span>
                ) : <div
                    onClick={() => event.type === 'delivery' ? fireEvent(event.status === 'finished' ? event.results_wall : event.announce_wall) : null}
                    style={{ ...(event.type === 'delivery' ? { height: Math.floor((window.screen.width - 24) * 480 / 1074) - 54 } : {}) }}
                />
            }
            style={{ marginTop: 0 }}
            background={
                <div
                    onClick={() => event.type === 'delivery' ? fireEvent(event.status === 'finished' ? event.results_wall : event.announce_wall) : null}
                    style={{
                        backgroundColor: event.color || '#4BB34B',
                        backgroundImage: `url(${event.image})`,
                        backgroundPosition: `${event.bgHorizontal || '150%'} ${event.bgVertical || 'center'}`,
                        backgroundSize: event.bgSize || '50%',
                        backgroundRepeat: 'no-repeat',
                    }}
                />
            }
            actions={[
                ...(event.type !== 'delivery' ? [<Button
                    key="wall"
                    style={{ marginTop: 10, marginBottom: 5 }}
                    mode="overlay_primary"
                    size="m"
                    onClick={() => {
                        fireEvent(event.status === 'finished' ? event.results_wall : event.announce_wall);
                        GA.event({
                            category: 'Events',
                            action: `Open ${event.status === 'finished' ? 'Results' : 'Announce'}`,
                            label: String(event._id),
                        });
                    }}
                >
                    {event.status === 'finished' ? 'Результаты' : 'Участвовать'}
                </Button>] : []),
                ...(event.type !== 'custom' && event.type !== 'delivery' ? [
                    <Button
                        key="rating"
                        style={{ marginTop: 10, marginLeft: 8 }}
                        mode="overlay_primary"
                        size="m"
                        onClick={() => {
                            openEvent(event);
                            openTab('event-rating');
                            GA.event({
                                category: 'Events',
                                action: 'Open Rating',
                                label: String(event._id),
                            });
                        }}
                    >
                        Рейтинг
                    </Button>,
                ] : []),
            ]}
        />
    );
};

EventCard.propTypes = {
    event: PropTypes.shape({
        _id: PropTypes.number.isRequired,
        partner: PropTypes.bool,
        title: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.string,
        startDate: PropTypes.number,
        endDate: PropTypes.number,
        color: PropTypes.string,
        image: PropTypes.string,
        announce_wall: PropTypes.string,
        results_wall: PropTypes.string,
        status: PropTypes.string,
        bgSize: PropTypes.string,
        bgHorizontal: PropTypes.string,
        bgVertical: PropTypes.string,
        disabled_rating: PropTypes.bool,
    }).isRequired,
    openEvent: PropTypes.func.isRequired,
    openTab: PropTypes.func.isRequired,
};

export default EventCard;
