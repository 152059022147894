import * as types from './actionTypes';

const initialState = {
    loaded: false,
    data: {},
    other: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.USER_LOAD: {
            const { user } = action;
            return {
                ...state,
                loaded: true,
                data: user,
            };
        }

        case types.USER_JOIN_TO_MATCH: {
            const { match_id } = action;
            return {
                ...state,
                data: {
                    ...state.data,
                    match_id,
                },
            };
        }

        case types.USER_UPDATE_GROUP: {
            const { group } = action;
            return {
                ...state,
                data: {
                    ...state.data,
                    group,
                },
            };
        }

        case types.USER_OPEN_OTHER_PROFILE: {
            const { user_id } = action;
            return {
                ...state,
                other: user_id,
            };
        }

        default: return state;
    }
};
