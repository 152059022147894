import React from 'react';
import PropTypes from 'prop-types';
import bridge from '@vkontakte/vk-bridge';
import wordPad from '../../utils/wordPad';
import { useSelector } from 'react-redux';
import { Panel, PanelHeader, Avatar, Group, Cell, List, Header, Button, PanelHeaderButton } from '@vkontakte/vkui';
import './index.scss';

import Icon28Favorite from '@vkontakte/icons/dist/28/favorite';
import Icon28PollSquareOutline from '@vkontakte/icons/dist/28/poll_square_outline';

const Profile = ({ id, openTab, openView }) => {
    const user = useSelector((store) => store.user.data);
    const stats = user && user.statistics;
    const group = useSelector((store) => store.user.data.group);
    const setupApp = () => {
        bridge.send('VKWebAppAddToCommunity', {});
    };

    return (
        <Panel id={id}>
            <PanelHeader
                left={
                    user.staff && (
                        <PanelHeaderButton onClick={() => openTab('admin')}>
                            <Icon28PollSquareOutline fill="#4BB34B" />
                        </PanelHeaderButton>
                    )
                }
            >
                Профиль
            </PanelHeader>
            <Group className="profile">
                <Avatar size={128} src={user && user.photo_200} />
                <div className="profile__name">{user && user.first_name} {user && user.last_name}</div>
            </Group>
            <Group>
                <List>
                    <Cell
                        multiline
                        before={
                            <Avatar style={{ background: 'var(--background_content)' }}>
                                <Icon28Favorite fill="#4BB34B" />
                            </Avatar>
                        }
                        description="Очки влияют на вашу позицию в рейтинге пользователей"
                    >
                        {user && user.rating} {wordPad(user && user.rating, 'очко', 'очка', 'очков')}
                    </Cell>
                </List>
            </Group>
            <Group
                header={<Header mode="secondary">Группа</Header>}
                description="Очки за ваши игры начисляются выбранной группе и напрямую влияют на ее рейтинг в топе"
            >
                <List>
                    <Cell
                        expandable
                        before={<Avatar src={group && group.photo_200} />}
                        description="Нажмите, чтобы изменить"
                        onClick={() => openView('select-group')}
                    >
                        {(group && group.name) || 'Не выбрана'}
                    </Cell>
                </List>
            </Group>
            <Group header={<Header mode="secondary">Для групп</Header>}>
                <List>
                    <Cell
                        multiline
                        description="Позволит увеличить количество игроков"
                        asideContent={
                            <Button
                                onClick={() => setupApp()}
                                style={{ backgroundColor: '#4BB34B' }}
                            >
                                Добавить
                            </Button>
                        }
                    >
                        Добавить игру в группу
                    </Cell>
                </List>
            </Group>
            <Group header={<Header mode="secondary">Статистика</Header>}>
                <List>
                    <Cell
                        indicator={stats && stats.right_answers}
                    >
                        Правильных ответов
                    </Cell>
                    <Cell
                        indicator={stats && stats.all_answers}
                    >
                        Всего ответов
                    </Cell>
                    <Cell
                        indicator={stats && stats.win_matches}
                    >
                        Выигранных матчей
                    </Cell>
                    <Cell
                        indicator={stats && stats.all_matches}
                    >
                        Всего матчей
                    </Cell>
                </List>
            </Group>
        </Panel>
    );
};

Profile.propTypes = {
    id: PropTypes.string.isRequired,
    openTab: PropTypes.func.isRequired,
    openView: PropTypes.func.isRequired,
};

export default Profile;
