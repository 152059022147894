import openSocket from 'socket.io-client';
import bridge from '@vkontakte/vk-bridge';
import request from './utils/request';
import GA from 'react-ga';

const API_SERVER = 'https://back.nimble.juice.vk-apps.com/';
const MM_SERVER = 'https://mm.nimble.juice.vk-apps.com';
const TOKEN = btoa(window.location.search.slice(1, window.location.search.length + 1));
const IS_DEV = window.location.href.indexOf('vk_app_id') === -1;

const mmInstance = openSocket(MM_SERVER + '/?token=' + TOKEN, {
    autoConnect: false,
    transports: ['websocket'],
});

export const subscribeOnMMServer = (callback) => {
    mmInstance.connect();
    mmInstance.on('connect', () => {
        GA.event({
            category: 'MatchMaker',
            action: 'login',
        });

        callback({ type: 'connected' });
    });
    mmInstance.on('disconnect', () => {
        GA.event({
            category: 'MatchMaker',
            action: 'logout',
        });

        callback({ type: 'disconnected' });
    });
    // eslint-disable-next-line camelcase
    mmInstance.on('battle', (chat_url) => {
        GA.event({
            category: 'MatchMaker',
            action: 'battle_created',
        });

        mmInstance.disconnect();

        return callback({
            type: 'battle',
            payload: chat_url,
        });
    });

    return () => {
        mmInstance.disconnect();
    };
};

export const getUser = () => new Promise((resolve) => {
    if (IS_DEV) {
        return resolve({
            'id': 2314852,
            'first_name': 'Ирина',
            'last_name': 'Денежкина',
            'sex': 1,
            'city': {
                'id': 2,
                'title': 'Санкт-Петербург',
            },
            'country': {
                'id': 1,
                'title': 'Россия',
            },
            'bdate': '10.4.1990',
            'photo_100': 'https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg',
            'photo_200': 'https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg',
            'timezone': 3,
        });
    }

    request(API_SERVER + 'user/login', {
        token: TOKEN,
    })
        .then((user) => resolve(user))
        .catch(() => resolve(null));
});

export const getUserById = (userId) => new Promise((resolve) => {
    request(API_SERVER + 'user/get', {
        token: TOKEN,
        user_id: userId,
    })
        .then((response) => resolve(response.user))
        .catch(() => resolve(null));
});

export const getUsersRating = () => new Promise((resolve) => {
    request(API_SERVER + 'rating/users', {
        token: TOKEN,
    })
        .then((user) => resolve(user))
        .catch(() => resolve(null));
});

export const getGroupsRating = () => new Promise((resolve) => {
    request(API_SERVER + 'rating/groups', {
        token: TOKEN,
    })
        .then((user) => resolve(user))
        .catch(() => resolve(null));
});

export const getMatches = () => new Promise((resolve) => {
    request(API_SERVER + 'match/list', {
        token: TOKEN,
    })
        .then((response) => resolve(response.items))
        .catch(() => resolve([]));
});

export const changeGroup = (groupId) => new Promise((resolve, reject) => {
    request(API_SERVER + 'user/changeGroup', {
        token: TOKEN,
        group_id: groupId,
    })
        .then(() => resolve())
        .catch(() => reject());
});

export const getVKStore = () => new Promise((resolve) => {
    bridge.send('VKWebAppStorageGet', {
        keys: ['welcome'],
    })
        .then((response) => {
            const compiled = response.keys.reduce((a, x) => {
                a[x.key] = x.value;
                return a;
            }, {});
            resolve(compiled);
        })
        .catch(() => resolve({}));
});

export const setVKStorage = (key, value) => new Promise((resolve) => {
    bridge.send('VKWebAppStorageSet', {
        key,
        value,
    })
        .then(() => resolve())
        .catch(() => resolve());
});

export const getSystemStats = () => new Promise((resolve) => {
    request(API_SERVER + 'system/stats', {
        token: TOKEN,
    })
        .then((stats) => resolve(stats))
        .catch(() => resolve({}));
});

export const getDailyWinsRating = (eventId) => new Promise((resolve) => {
    request(API_SERVER + 'event/getDailyWinsRating', {
        token: TOKEN,
        event_id: eventId,
    })
        .then((rating) => resolve(rating))
        .catch(() => resolve({}));
});

export const getDailyAnswersRating = (eventId) => new Promise((resolve) => {
    request(API_SERVER + 'event/getDailyAnswersRating', {
        token: TOKEN,
        event_id: eventId,
    })
        .then((rating) => resolve(rating))
        .catch(() => resolve({}));
});

export const getAnswersRating = (eventId) => new Promise((resolve) => {
    request(API_SERVER + 'event/getAnswersRating', {
        token: TOKEN,
        event_id: eventId,
    })
        .then((rating) => resolve(rating))
        .catch(() => resolve({}));
});

export const getEventRating = (eventId) => new Promise((resolve) => {
    request(API_SERVER + 'event/getRating', {
        token: TOKEN,
        event_id: eventId,
    })
        .then((rating) => resolve(rating))
        .catch(() => resolve({}));
});

export const createPVP = (player1, player2) => new Promise((resolve) => {
    request(API_SERVER + 'match/createPVP', {
        token: TOKEN,
        player_1: player1,
        player_2: player2,
    })
        .then((match) => resolve(match))
        .catch(() => resolve(null));
});

export const getGraph = (userId, field = 'rating') => new Promise((resolve) => {
    request(API_SERVER + 'system/getGraph', {
        token: TOKEN,
        user_id: userId,
        field,
    })
        .then((graph) => resolve(graph))
        .catch(() => resolve([]));
});

export const getEvents = () => new Promise((resolve, reject) => {
    request(API_SERVER + 'event/list', {
        token: TOKEN,
    })
        .then((response) => resolve(response.items))
        .catch(() => reject());
});

export const changeUserRatingVisible = (userId) => new Promise((resolve) => {
    request(API_SERVER + 'system/changeUserRatingVisible', {
        token: TOKEN,
        user_id: userId,
    })
        .then((graph) => resolve(graph))
        .catch(() => resolve([]));
});

export const getFriendsTop = (ids) => new Promise((resolve, reject) => {
    request(API_SERVER + 'rating/friends', {
        token: TOKEN,
        ids: ids.join(','),
    })
        .then((rating) => resolve(rating))
        .catch(() => reject());
});

export const getPromocode = (match_id) => new Promise((resolve) => {
    request(API_SERVER + 'promocode/get', {
        token: TOKEN,
        match_id
    })
        .then((result) => resolve(result.promocode))
        .catch(() => resolve(null));
});