import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Panel, PanelHeader, PanelHeaderBack, Group, Header, SimpleCell, Spinner } from '@vkontakte/vkui';
import * as API from '../../api';

const SystemStats = ({ id, openTab }) => {
    const [stats, setStats] = useState({});

    useEffect(() => {
        API.getSystemStats().then(setStats);
        const timerId = setInterval(() => API.getSystemStats().then(setStats), 5000);
        return () => {
            clearInterval(timerId);
        };
    }, []);

    return (
        <Panel id={id}>
            <PanelHeader
                left={<PanelHeaderBack onClick={() => openTab('admin')} />}
            >
                Статистика
            </PanelHeader>
            {Object.keys(stats).length === 0 && (
                <div style={{ marginTop: 15 }}>
                    <Spinner/>
                </div>
            )}
            {stats.matches && (
                <Group>
                    <Header mode="secondary">Матчи</Header>
                    <SimpleCell indicator={stats.matches.count}>
                        Общее количество матчей
                    </SimpleCell>
                    <SimpleCell indicator={stats.matches.active}>
                        Активные матчи
                    </SimpleCell>
                </Group>
            )}
            {stats.users && (
                <Group>
                    <Header mode="secondary">Пользователи</Header>
                    <SimpleCell indicator={stats.users.count}>
                        Общее количество пользователей
                    </SimpleCell>
                    <SimpleCell indicator={stats.users.active}>
                        Активные пользователи
                    </SimpleCell>
                    <SimpleCell indicator={stats.users.clan}>
                        Установившие группу
                    </SimpleCell>
                    <SimpleCell indicator={stats.users.playing}>
                        Играющие прямо сейчас
                    </SimpleCell>
                </Group>
            )}
            {stats.groups && (
                <Group>
                    <Header mode="secondary">Группы</Header>
                    <SimpleCell indicator={stats.groups.count}>
                        Общее количество групп
                    </SimpleCell>
                </Group>
            )}
            {stats.questions && (
                <Group>
                    <Header mode="secondary">Вопросы</Header>
                    <SimpleCell indicator={String(stats.questions.count)}>
                        Общее количество вопросов
                    </SimpleCell>
                    <SimpleCell indicator={String(stats.questions.active)}>
                        Активные вопросы
                    </SimpleCell>
                    <SimpleCell indicator={String(stats.questions.moderation)}>
                        Вопросы ожидающие модерации
                    </SimpleCell>
                </Group>
            )}
            {stats.events && (
                <Group>
                    <Header mode="secondary">События</Header>
                    <SimpleCell indicator={stats.events.count}>
                        Общее количество событий
                    </SimpleCell>
                    <SimpleCell indicator={stats.events.active}>
                        Активные события
                    </SimpleCell>
                    <SimpleCell indicator={stats.events.finishedEvents || stats.events.finished}>
                        Завершенные события
                    </SimpleCell>
                    <SimpleCell indicator={stats.events.preparing}>
                        Готовящиеся события
                    </SimpleCell>
                    <SimpleCell indicator={stats.events.partners}>
                        События от партнеров
                    </SimpleCell>
                </Group>
            )}
        </Panel>
    );
};

SystemStats.propTypes = {
    id: PropTypes.string.isRequired,
    openTab: PropTypes.func.isRequired,
};

export default SystemStats;
