import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

const GraphBar = ({ source }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const rendered = source.reduce((a, x) => {
            if (a[x.y]) {
                a[x.y] += 1;
            } else {
                a[x.y] = 1;
            }

            return a;
        }, {});
        console.log(rendered);
        setData(rendered);
    }, [source]);

    return (
        <Bar
            height={200}
            options={{
                maintainAspectRatio: false,
                legend: { display: false },
                gridLines: { lineWidth: 0 },
                tooltips: { enabled: false },
            }}
            data={{
                labels: Object.keys(data),
                datasets: [{
                    backgroundColor: 'rgba(75, 179, 75, .25)',
                    borderColor: '#4BB34B',
                    borderWidth: 1,
                    data: Object.keys(data).map((x) => data[x]),
                }],
            }}
        />
    );
};

GraphBar.propTypes = {
    source: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default GraphBar;
