import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Panel, PanelHeader, PanelHeaderBack, Group, Header, List, Counter, Cell, Spinner, CellButton, SelectMimicry, Div, Input, FormLayout } from '@vkontakte/vkui';
import * as API from '../../api';

import Icon28BrainOutline from '@vkontakte/icons/dist/28/brain_outline';
import Icon28UsersOutline from '@vkontakte/icons/dist/28/users_outline';
import Icon28Users3Outline from '@vkontakte/icons/dist/28/users_3_outline';
import Icon28HelpOutline from '@vkontakte/icons/dist/28/help_outline';
import Icon28CalendarOutline from '@vkontakte/icons/dist/28/calendar_outline';

import { openOtherProfile } from '../../store/user/actions';

const Admin = ({ id, openTab }) => {
    const dispatch = useDispatch();
    const [stats, setStats] = useState({});
    const [playerProfile, setPlayerProfile] = useState('');

    const selectPlayerProfile = () => {
        bridge.send('VKWebAppGetFriends', {})
            .then((response) => {
                dispatch(openOtherProfile({ _id: Number(response.users[0].id), from: 'admin' }));
                openTab('profile-other');
            })
            .catch(() => null);
    };

    useEffect(() => {
        API.getSystemStats().then(setStats);
        const timerId = setInterval(() => API.getSystemStats().then(setStats), 5000);
        return () => {
            clearInterval(timerId);
        };
    }, []);

    return (
        <Panel id={id}>
            <PanelHeader
                left={
                    <PanelHeaderBack onClick={() => openTab('profile')} />
                }
            >
                Платформа
            </PanelHeader>
            <Group>
                <Header mode="secondary">Статистика</Header>
                <List>
                    <Cell
                        before={<Icon28BrainOutline />}
                        indicator={
                            stats.matches ? (
                                <Counter mode="primary">
                                    {stats.matches && stats.matches.count}
                                </Counter>
                            ) : <Spinner />
                        }
                    >
                        Матчи
                    </Cell>
                    <Cell
                        before={<Icon28UsersOutline />}
                        indicator={
                            stats.users ? (
                                <Counter mode="primary">
                                    {stats.users && stats.users.count}
                                </Counter>
                            ) : <Spinner />
                        }
                    >
                        Пользователи
                    </Cell>
                    <Cell
                        before={<Icon28Users3Outline />}
                        indicator={
                            stats.groups ? (
                                <Counter mode="primary">
                                    {stats.groups && stats.groups.count}
                                </Counter>
                            ) : <Spinner />
                        }
                    >
                        Группы
                    </Cell>
                    <Cell
                        before={<Icon28HelpOutline />}
                        indicator={
                            stats.questions ? (
                                <Counter mode="primary">
                                    {stats.questions && stats.questions.count}
                                </Counter>
                            ) : <Spinner />
                        }
                    >
                        Вопросы
                    </Cell>
                    <Cell
                        before={<Icon28CalendarOutline />}
                        indicator={
                            stats.events ? (
                                <Counter mode="primary">
                                    {stats.events && stats.events.count}
                                </Counter>
                            ) : <Spinner />
                        }
                    >
                        События
                    </Cell>
                    <Cell
                        before={<Icon28CalendarOutline />}
                        indicator={
                            stats.promocodes ? (
                                <Counter mode="primary">
                                    {stats.promocodes && stats.promocodes.count}
                                </Counter>
                            ) : <Spinner />
                        }
                    >
                        Промокоды
                    </Cell>
                </List>
                <CellButton
                    align="center"
                    onClick={() => openTab('system-stats')}
                >
                    Показать полную статистику
                </CellButton>
            </Group>
            <Group>
                <Header mode="secondary">Открыть профиль</Header>
                <FormLayout>
                    <Input
                        top="ID игрока"
                        value={playerProfile}
                        onChange={(e) => setPlayerProfile(e.currentTarget.value)}
                    />
                </FormLayout>
                <div style={{ display: 'flex' }}>
                    <CellButton
                        align="center"
                        onClick={selectPlayerProfile}
                    >
                        Выбрать из друзей
                    </CellButton>
                    <CellButton
                        align="center"
                        onClick={() => {
                            dispatch(openOtherProfile({ _id: Number(playerProfile), from: 'admin' }));
                            openTab('profile-other');
                        }}
                    >
                        Открыть
                    </CellButton>
                </div>
            </Group>
        </Panel>
    );
};

Admin.propTypes = {
    id: PropTypes.string.isRequired,
    openTab: PropTypes.func.isRequired,
};

export default Admin;
