import * as types from './actionTypes';

export const loadUser = (user = {}) => {
    return {
        type: types.USER_LOAD,
        user,
    };
};

export const userJoinToMatch = (matchId) => {
    return {
        type: types.USER_JOIN_TO_MATCH,
        match_id: matchId,
    };
};

export const userUpdateGroup = (group) => {
    return {
        type: types.USER_UPDATE_GROUP,
        group: group.id === '-1' ? {} : group,
    };
};

export const openOtherProfile = (userId) => {
    return {
        type: types.USER_OPEN_OTHER_PROFILE,
        user_id: userId,
    };
};
