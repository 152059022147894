import React from 'react';
import GA from 'react-ga';
import bridge from '@vkontakte/vk-bridge';
import PropTypes from 'prop-types';
import fireEvent from '../../utils/FireEvent';

import { useSelector } from 'react-redux';
import { ModalCard, Avatar } from '@vkontakte/vkui';

const SelectGroupModal = ({ id, changeModal }) => {
    const user = useSelector((store) => store.user.data);

    return (
        <ModalCard
            id={id}
            onClose={() => changeModal(null)}
            icon={<Avatar src="/assets/eats_logo.png" size={46} style={{ background: '#ff6666', padding: 10 }} />}
            header={user.dc_promocode || "Коды закончились :("}
            caption="Промокод на скидку 20% действует на любой заказ из ресторанов через приложение Еда ВКонтакте. Да-да, теперь любимую еду можно заказать прямо ВКонтакте."
            actionsLayout="vertical"
            actions={[
                {
                    title: 'Открыть "Еда ВКонтакте"',
                    mode: 'primary',
                    action: () => {
                        fireEvent('https://vk.com/app7252141?=&ref=snippet_im#promo=NIMBLE&utm_medium=nimble');
                        changeModal(null);
                        GA.event({
                            category: 'DC',
                            action: 'Open app',
                        });
                    },
                },
                {
                    title: 'Скопировать промокод',
                    mode: 'secondary',
                    action: () => {
                        bridge.send('VKWebAppCopyText', {
                            text: user.dc_promocode,
                        });
                        changeModal(null);
                        GA.event({
                            category: 'DC',
                            action: 'Copy code',
                        });
                    },
                }
            ]}
        >

        </ModalCard>
    );
};

SelectGroupModal.propTypes = {
    id: PropTypes.string.isRequired,
    changeModal: PropTypes.func.isRequired,
};

export default SelectGroupModal;
