import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import bridge from '@vkontakte/vk-bridge';
import { useDispatch, useSelector } from 'react-redux';
import { Panel, Button, PromoBanner, FixedLayout } from '@vkontakte/vkui';
import * as API from '../../api';
import fireEvent from '../../utils/FireEvent';
import './index.scss';

import Dodecahedron from '../../components/Dodecahedron';

import { userJoinToMatch } from '../../store/user/actions';
import { addMatch } from '../../store/matches/actions';

const MatchMaking = ({ id, openView }) => {
    const [ads, setAds] = useState(null);

    const dispatch = useDispatch();
    const matchId = useSelector((store) => store.user.data.match_id);
    const matches = useSelector((store) => store.matches.list);

    const [status, setStatus] = useState(matchId === null ? 'connecting' : 'battle');
    const [timer, setTimer] = useState(0);
    const [timerText, setTimerText] = useState('00:00');
    const updateTimer = () => {
        setTimer((prev) => {
            renderTime(prev + 1);
            return prev + 1;
        });
    };

    useEffect(() => {
        bridge.send('VKWebAppGetAds')
            .then(setAds);
    }, []);

    useEffect(() => {
        if (matchId === null) {
            const timerId = setInterval(() => updateTimer(), 1000);
            const disconnectFromMM = API.subscribeOnMMServer(({ type, payload }) => {
                switch (type) {
                    case 'connected': {
                        return setStatus('connected');
                    }

                    case 'disconnected': {
                        return setStatus('disconnected');
                    }

                    case 'battle': {
                        dispatch(addMatch(payload));
                        dispatch(userJoinToMatch(payload._id));
                        clearInterval(timerId);
                        return setStatus('battle');
                    }

                    default:
                        return;
                }
            });

            return () => {
                clearInterval(timerId);
                disconnectFromMM();
            };
        }
    }, [matchId]);

    const renderTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time - minutes * 60;
        setTimerText(`${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`);
    };

    return (
        <Panel id={id} separator={false}>
            <div className={`matchmaking ${matchId && 'matchmaking-finded'}`}>
                <div className='matchmaking__timer'>
                    <div className={`matchmaking__timer__content ${matchId && 'matchmaking__timer__content-finded'}`}>
                        {timerText}
                    </div>
                </div>
                <Dodecahedron />
                <div className={`matchmaking__bottom ${ads && 'matchmaking__bottom-ads'}`}>
                    <div className='matchmaking__text'>
                        {{
                            'connecting': 'Подключение к серверу',
                            'disconnected': 'Переподключение',
                            'connected': 'Поиск соперника',
                            'battle': 'Игра найдена',
                        }[status]}
                        {!matchId && '.'.repeat(Math.floor(timer % 3) + 1)}
                    </div>
                    <Button
                        mode={matchId ? 'overlay_primary' : 'overlay_outline'}
                        size='xl'
                        onClick={matchId ? () => {
                            fireEvent(matches.find((x) => x._id === matchId).chat_url);
                            openView('main');
                        } : () => openView('main')}
                    >
                        {matchId ? 'Присоединиться' : 'Выход'}
                    </Button>
                </div>
            </div>
            <div style={{ position: 'absolute', bottom: 0, width: '100vw' }}>
                {ads && <PromoBanner onClose={() => setAds(null)} bannerData={ads} />}
            </div>
        </Panel>
    );
};

MatchMaking.propTypes = {
    id: PropTypes.string.isRequired,
    openView: PropTypes.func.isRequired,
};

export default MatchMaking;
