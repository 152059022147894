import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Avatar } from '@vkontakte/vkui';
import './index.scss';

const Message = ({ avatar, from, text, time, buttons }) => {
    const [shown, setShown] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setShown(true), 490);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <div className={`message__wrapper ${shown && 'message__wrapper-shown'} ${from === 'me' && 'message__wrapper-right'}`}>
            {from !== 'me' && (
                <div className="message__avatar">
                    <Avatar src={avatar} size={32} />
                </div>
            )}
            <div className="message">
                <div className="message__container">
                    {from !== 'me' && (
                        <div className="message__from">
                            {from}
                        </div>
                    )}
                    <div className="message__content">
                        <div className="message__text">
                            {text}
                        </div>
                        <div className="message__time">
                            {time}
                        </div>
                    </div>
                    {buttons && (
                        <div className="message__buttons">
                            {buttons.map((btn) => (
                                <Button
                                    key={btn.text}
                                    size="l"
                                    mode="secondary"
                                    onClick={btn.handler || null}
                                >
                                    {btn.text}
                                </Button>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

Message.propTypes = {
    avatar: PropTypes.string,
    from: PropTypes.string,
    text: PropTypes.string,
    time: PropTypes.string,
    buttons: PropTypes.array,
};

export default Message;
