import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

const Graph = ({ source, freq = 25 }) => {
    return (
        <Line
            height={200}
            options={{
                maintainAspectRatio: false,
                legend: { display: false },
                gridLines: { lineWidth: 0 },
                tooltips: { enabled: false },
            }}
            data={{
                labels: source.map((x, k) => source.length > freq ? k % Math.floor(source.length / freq) === 0 ? x.y : NaN : x.y).filter((x) => !isNaN(x)).map(() => ''),
                datasets: [{
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: '#4BB34B',
                    borderColor: '#4BB34B',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#4BB34B',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#4BB34B',
                    pointHoverBorderColor: '#4BB34B',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: source.map((x, k) => source.length > freq ? k % Math.floor(source.length / freq) === 0 ? x.y : NaN : x.y).filter((x) => !isNaN(x)),
                }],
            }}
        />
    );
};

Graph.propTypes = {
    source: PropTypes.arrayOf(PropTypes.number).isRequired,
    freq: PropTypes.number,
};

export default Graph;
