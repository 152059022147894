import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { View } from '@vkontakte/vkui';
import * as API from '../../api';
import GA from 'react-ga';

import First from './First';
import Second from './Second';
import Third from './Third';
import Fourth from './Fourth';

const Onboard = ({ id, openView }) => {
    const [activePanel, setActivePanel] = useState('first');
    const close = () => {
        openView('main');
        API.setVKStorage('welcome', '1');

        GA.event({
            category: 'User',
            action: 'close_onboarding',
        });
    };

    return (
        <View id={id} activePanel={activePanel} header={false}>
            <First id='first' nextSlide={() => setActivePanel('second')} />
            <Second id='second' nextSlide={() => setActivePanel('third')} />
            <Third id='third' nextSlide={() => setActivePanel('fourth')} />
            <Fourth id='fourth' nextSlide={close} />
        </View>
    );
};

Onboard.propTypes = {
    id: PropTypes.string.isRequired,
    openView: PropTypes.func.isRequired,
};

export default Onboard;
