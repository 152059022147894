import 'core-js/features/map';
import 'core-js/features/set';

import React from 'react';
import ReactDOM from 'react-dom';
import bridge from '@vkontakte/vk-bridge';
import GA from 'react-ga';
import * as Sentry from '@sentry/browser';

import App from './containers';
import '@vkontakte/vkui/dist/vkui.css';

import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './store';
import * as API from './api';

import { loadUser } from './store/user/actions';
import { loadMatches } from './store/matches/actions';

// Error logging
Sentry.init({
    dsn: 'https://4e834509e32d481b84ba2f2dc07453d0@sentry.vk-admin.com/3',
    environment: process.env.NODE_ENV,
});

// Global config
const search = window.location.search.slice(1, window.location.search.length).replace(/&amp;/g, '&');
window.vkapp_config = search.split('&').reduce((a, param) => {
    const data = param.split('=');
    if (data[0] === 'vk_app_id') {
        a[data[0]] = Number(data[1]);
    } else {
        a[data[0]] = data[1];
    }
    return a;
}, {});

Sentry.setUser({
    id: window.vkapp_config.vk_user_id || -1,
});

bridge.send('VKWebAppInit');

// Analytics
GA.initialize('UA-165005942-1', {
    gaOptions: {
        userId: window.vkapp_config.vk_user_id || '',
    },
});

export const store = createStore(rootReducer);
API.getUser().then((response) => {
    if (response !== null) {
        store.dispatch(loadUser(response.user));
        store.dispatch(loadMatches(response.matches));
    }
});

bridge.subscribe(({ detail: { type, data } }) => {
    if (type === 'VKWebAppUpdateConfig') {
        const schemeAttribute = document.createAttribute('scheme');
        schemeAttribute.value = data.scheme ? data.scheme : 'bright_light';
        document.body.attributes.setNamedItem(schemeAttribute);
    }

    if (type === 'VKWebAppViewRestore') {
        API.getUser().then((response) => {
            if (response !== null) {
                store.dispatch(loadUser(response.user));
            }
        });
    }
});

setInterval(() => {
    API.getUser()
        .then((response) => {
            if (response !== null) {
                store.dispatch(loadUser(response.user));
            }
        });
}, 10000);

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
