import * as types from './activeTypes';

const initialState = {
    loaded_users: false,
    loaded_groups: false,
    users: [],
    groups: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.RATING_LOAD: {
            const { field, list } = action;
            return {
                ...state,
                ['loaded_' + field]: field,
                [field]: list,
            };
        }

        default: return state;
    }
};
