import React from 'react';
import PropTypes from 'prop-types';
import { Button, Cell, Avatar } from '@vkontakte/vkui';
import './index.scss';

import Icon16Done from '@vkontakte/icons/dist/16/done';

const ActionCard = ({ done = false, icon = null, action, title, subtitle }) => {
    return (
        <div className="action-card">
            <Cell
                multiline
                description={subtitle}
                before={
                    <Avatar
                        style={{ backgroundColor: 'white' }}
                        size={24}
                    >
                        {React.cloneElement(icon, { fill: '#4BB34B' })}
                    </Avatar>
                }
                asideContent={
                    <Button
                        disabled={done}
                        onClick={action}
                        className={done ? 'action-card__button action-card__button-done' : 'action-card__button'}
                    >
                        {done ? <Icon16Done fill="white" /> : 'Го!'}
                    </Button>
                }
            >
                <div className="action-card__title">{title}</div>
            </Cell>
        </div>
    );
};

ActionCard.propTypes = {
    done: PropTypes.bool,
    icon: PropTypes.node,
    action: PropTypes.func,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
};

export default ActionCard;
